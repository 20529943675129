import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';

const SidebarLogo = () => {
  const { config } = useLayout();

  return (
    <div
      className="app-sidebar-logo px-6 d-flex justify-content-center"
      id="kt_app_sidebar_logo"
      style={{ borderBottom: '0px', marginTop: '20px' }}
    >
      <Link to="/dashboard">
        {config.layoutType === 'dark-sidebar' ? (
          <img
            alt="Logo"
            src={toAbsoluteUrl('/media/logos/default-dark.svg')}
            className="h-80px app-sidebar-logo-default"
          />
        ) : (
          <>
            <img
              alt="Logo"
              src={toAbsoluteUrl('/media/logos/default.svg')}
              className="h-80px app-sidebar-logo-default theme-light-show"
            />
            <img
              alt="Logo"
              src={toAbsoluteUrl('/media/logos/default-dark.svg')}
              className="h-80px app-sidebar-logo-default theme-dark-show"
            />
          </>
        )}
      </Link>
    </div>
  );
};

export { SidebarLogo };