import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Formik, Form, FormikHelpers} from 'formik'
import {ICreateSessionVR, generateSessionVRSchemas, inits} from './GenerateSessionVRChecker'
import {generateSessionVR} from '../../../api/sessionVRApi'
import {Modal} from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import { useAuth } from '../../../app/modules/auth/core/Auth';

const Genera_sessioneVR: React.FC = () => {
  const [initValues] = useState<ICreateSessionVR>(inits)
  const [showModal, setShowModal] = useState(false)
  const [modalMessage, setModalMessage] = useState<any>('');

  const {currentUser, logout} = useAuth()
  const user = Array.isArray(currentUser) && currentUser.length > 0 ? currentUser[0] : currentUser;

  const submitForm = async (values: ICreateSessionVR, actions: FormikHelpers<ICreateSessionVR>) => {
    try {
      values.COD_User_SVR = user.COD_User
      const response = await generateSessionVR(values)
      if (response.status === 201) {
        const message = response.data;
        const substring1 = message.substring(0, 30);
        const substring2 = message.substring(30, 38);
        const newModal = {prefazione: substring1, codiceSessioneVR: substring2};
        setModalMessage(newModal);
        //setModalMessage(response.data);
      }
      setShowModal(true);
	  
	    // Reset dei campi del form
      actions.resetForm();
	  
      } catch (error: any) {
        console.error('Errore nella chiamata API:', error);
        console.error('Errore nella chiamata API 2:', error.data);
        if (error.response) {
          // La richiesta è stata effettuata e il server ha risposto con uno stato fuori dal range 2xx
          if (error.response.status === 400) {
            setModalMessage(error.response.data); //esiste già un visore con questi seriali
          }
          else if (error.response.status === 419) {
            setModalMessage(error.response.data); //almeno uno dei due seriali deve essere inserito
          }
        } else if (error.request) {
          // La richiesta è stata effettuata, ma non c'è stata alcuna risposta
          setModalMessage(error.response.data);
        } else {
          // Qualcosa è andato storto nella creazione della richiesta
          setModalMessage(error.response.data);
        }
        setShowModal(true);
      }
      actions.resetForm()
  }

  const closeModal = () => setShowModal(false)

  return (
    <div className="d-flex justify-content-center">
      <div className='d-flex flex-column bg-body rounded w-100' style={{ maxWidth: '700px' }}>
        <Formik initialValues={initValues} validationSchema={generateSessionVRSchemas} onSubmit={submitForm}>
          {({ setFieldValue }) => (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>

              <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder text-dark'>Sessione VR</h2>
                <div className='text-gray-400 fw-bold fs-6'>Inserisci i dati</div>
              </div>

              {/* Nome formatore */}
              <div className='fv-row mb-10'>
                <label htmlFor='Nome_formatore' className='form-label required'>
                  Nome formatore
                </label>
                <Field
                  type='text'
                  id='Nome_formatore'
                  name='Nome_formatore'
                  className='form-control form-control-lg form-control-solid'
                />
          
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Nome_formatore' />
                  </div>
              </div>

              {/* Cognome formatore */}
              <div className='fv-row mb-10'>
                <label htmlFor='Cognome_formatore' className='form-label required'>
                  Cognome formatore
                </label>
                <Field
                  type='text'
                  id='Cognome_formatore'
                  name='Cognome_formatore'
                  className='form-control form-control-lg form-control-solid'
                />
          
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Cognome_formatore' />
                  </div>
              </div>

              {/* Data inizio sessione */}
              <div className='fv-row mb-10'>
                <label htmlFor='Data_inizio' className='form-label required'>
                  Data inizio
                </label>
                <Field
                  type='date'
                  id='Data_inizio'
                  name='Data_inizio'
                  className='form-control form-control-lg form-control-solid'
                />
                
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Data_inizio' />
                  </div>
              </div>
              
              <div className='d-flex flex-stack pt-10'>
                <button type='submit' className='btn btn-lg btn-primary me-3'>
                  Invia
                  <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0' />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Sessione VR generata correttamente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: 'center' }}>
            <div>{modalMessage.prefazione}</div>
            <div style={{ fontSize: '24px', fontWeight: 'bold', marginTop: '20px' }}>
              {modalMessage.codiceSessioneVR}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={closeModal}>
            Chiudi
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export { Genera_sessioneVR }
