import axios from '../config/axiosConfig';

/* Elenco dei risultati dei livelli */
export const listLevelResult = async (idUser) => {
  try {
    const response = await axios.post('/api/levelResult/list', { idUser });
    return response.data;
  } catch (error) {
    console.error('Error fetching level results: ', error);
    throw error;
  }
};

/* Dettagli risultato livello */
export const levelResultDetails = async (id) => {
  try {
    const response = await axios.get(`/api/levelResult/status/processing/details/${id}`);
    return response.data;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error fetching details level result:', error);
    throw error;
  }
};

/* Elimina risultato livello individuale */
export const deleteLevelResult = async (id) => {
  try {
    const response = await axios.put('/api/levelResult/delete', { id });
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error deleting level result:', error);
    throw error;
  }
};
