/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from "react"
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {sessionvrDetailsToEdit} from '../../../../api/sessionVRApi'
import {editSessionvr} from '../../../../api/sessionVRApi'

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: string;
};

const modalsRoot = document.getElementById('root-modals') || document.body

const EditSessionVRAperta = ({ show, handleClose, recordId }: Props) => {
  const [idSessionVR, setIDSessionVR] = useState<string>('');
  const [nameTrainer, setNameTrainer] = useState<string>('');
  const [surnameTrainer, setSurnameTrainer] = useState<string>('');
  const [dateStartSessionVR, setDateStartSessionVR] = useState<string >('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  function convertISOToYMD(isoString:any) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    // getMonth() restituisce un numero da 0 (gennaio) a 11 (dicembre), quindi aggiungi 1.
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Funzione per caricare i dati della sessione vr di riferimento dal backend
  useEffect(() => {
    const fetchSessionVRDetails = async (id: string) => {
      try {
        const response = await sessionvrDetailsToEdit(id);
        console.log("provaa", response)
        if(response && response.length > 0) {
          const sessionDetails = response[0];
          setIDSessionVR(sessionDetails.ID_Sessione_VR);
          setNameTrainer(sessionDetails.Nome_formatore);
          setSurnameTrainer(sessionDetails.Cognome_formatore);
          setDateStartSessionVR(convertISOToYMD(sessionDetails.Data_inizio));
        }
      } catch (error) {
        console.error('Errore nel recupero dei dettagli sessione vr:', error);
      }
    };

    if (show && recordId) {
      fetchSessionVRDetails(recordId);
    }
  }, [show, recordId]);


  const handleEditSessionVR = async (event: any) => {
    event.preventDefault();
    try {
      const sessionvrData = {
        ID_Sessione_VR: idSessionVR,
        Nome_formatore: nameTrainer,
        Cognome_formatore: surnameTrainer,
        Data_inizio: dateStartSessionVR,
      };
      const response = await editSessionvr(sessionvrData);
      handleClose();
      if (response.status === 200) {
        setModalMessage(response.data); 
      }
      setShowConfirmModal(true);
    } catch (error: any) {
      if (error.response) {
        // La richiesta è stata effettuata e il server ha risposto con uno stato fuori dal range 2xx
        if (error.response.status === 400) {
          setModalMessage(error.response.data);
        } else {
          setModalMessage(error.response.data);
        }
      } else if (error.request) {
        // La richiesta è stata effettuata, ma non c'è stata alcuna risposta
        setModalMessage(error.response.data);
      } else {
        // Qualcosa è andato storto nella creazione della richiesta
        setModalMessage(error.response.data);
      }
      setShowConfirmModal(true);
    }
  };

  const closeConfirmModal = () => setShowConfirmModal(false);

  return createPortal(
    <>
    <Modal
      id="kt_modal_update_delivery_effective"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Modifica - Sessione VR</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>
      <div className="modal-body">
      <form onSubmit={handleEditSessionVR}>
        {/*Nome formatore*/}
        <div className='fv-row mb-10'>
          <label htmlFor='Nome_formatore' className='form-label required'>
            Nome formatore
          </label>
          <input
            type="text"
            value={nameTrainer}
            onChange={(e) => setNameTrainer(e.target.value)}
            className='form-control form-control-lg form-control-solid'
          />
        </div>
        {/*Cognome formatore*/}
        <div className='fv-row mb-10'>
          <label htmlFor='Cognome_formatore' className='form-label required'>
            Cognome formatore
          </label>
          <input
            type="text"
            value={surnameTrainer}
            onChange={(e) => setSurnameTrainer(e.target.value)}
            className='form-control form-control-lg form-control-solid'
          />
        </div>
        {/*Data inizio sessione vr*/}
        <div className='fv-row mb-10'>
          <label htmlFor='Data_inizio_sessionevr' className='form-label required'>
            Data inizio sessione vr
          </label>
          <input
            type="date"
            value={dateStartSessionVR}
            onChange={(e) => setDateStartSessionVR(e.target.value)}
            className='form-control form-control-lg form-control-solid'
          />
        </div>
        <button type='submit' className='btn btn-lg btn-light-primary me-3'>Invio</button>
      </form>
      </div>
    </Modal>
    <Modal
      id="kt_modal_confirm_assign"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={showConfirmModal}
      onHide={closeConfirmModal}
    >
      <div className="modal-header">
        <h2>Modifica - Sessione VR</h2>
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={closeConfirmModal}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
      </div>
      <div className="modal-body">
        <p>{modalMessage}</p>
      </div>
      <div className="modal-footer">
        <button className='btn btn-lg btn-light-primary me-3' onClick={closeConfirmModal}>Chiudi</button>
      </div>
    </Modal>
    </>,
    modalsRoot
  );
};

export { EditSessionVRAperta };