import axios from '../config/axiosConfig';


/* Elenco delle sessioni VR */
export const listSessionVR = async (idUser) => {
  try {
    const response = await axios.post('/api/sessionVR/list', { idUser });
    return response.data;
  } catch (error) {
    console.error('Error fetching sessions vr: ', error);
    throw error;
  }
};

/* Generazione sessione vr */
export const generateSessionVR = async (sessionVRData) => {
  try {
    const response = await axios.post('/api/sessionVR/generate', sessionVRData);
    return response;
  } catch (error) {
    console.error('Error generating a VR session:', error);
    throw error;
  }
};

/* Dettagli sessione vr */
export const sessionvrDetails = async (id) => {
  try {
    const response = await axios.get(`/api/sessionVR/status/processing/details/${id}`);
    return response.data;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error fetching details session vr:', error);
    throw error;
  }
};

/* Dettagli sessione vr per modificarla */
export const sessionvrDetailsToEdit = async (id) => {
  try {
    const response = await axios.get(`/api/sessionVR/processing/detailsToEdit/${id}`);
    return response.data;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error fetching details session vr to edit:', error);
    throw error;
  }
};

/* Modifica sessione vr */
export const editSessionvr = async (sessionvrData) => {
  try {
    const response = await axios.put('/api/sessionVR/edit', { sessionvrData });
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error editing session vr:', error);
    throw error;
  }
};

/* Elimina sessione vr */
export const deleteSessionvr = async (id) => {
  try {
    const response = await axios.put('/api/sessionVR/delete', { id });
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error deleting session vr:', error);
    throw error;
  }
};

/* Ottieni dettagli report */
export const detailsReport = async (inputReport) => {
  try {
    const response = await axios.post('/api/sessionVR/detailsReport', { inputReport });
    return response.data;
  } catch (error) {
    console.error('Error generating report: ', error);
    throw error;
  }
};
